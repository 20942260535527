import PQueue from "p-queue";
const queue = new PQueue({ concurrency: 5 });

export const processFiles = async (
  files: any[],
  batchSize: number,
  setMsgInformation: (msg: string) => void,
  uploadFile: (file: any) => Promise<string | undefined>
) => {
  let batchCount = 0;
  const urlsFile = [] as any;

  for (let i = 0; i < files.length; i += batchSize) {
    batchCount += 1;
    const batch = files.slice(i, i + batchSize);

    setMsgInformation(`Subiendo archivo ${i + 1}/${files.length}...`);

    await Promise.all(
      batch.map(async (file: any) => {
        await queue.add(async () => {
          const fileName = file.name.split(".")[0];
          urlsFile.push({ [fileName]: await uploadFile(file) });
        });
      })
    );
  }

  setMsgInformation(``);

  return urlsFile;
};
