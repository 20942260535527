import {
  CardPdf,
  DragZone,
  CustomAlert,
  Box,
  Typography,
  LoadingButton,
  //@ts-ignore
} from "@enerbit/base";
import React, { useCallback, useState, useEffect } from "react";

import { onSelectDocument } from "../../../../services/uploadFile";

import bombi from "../../../../assets/cargando-enerbit.gif";
import { sendForm } from "../../../../helpers/sendForm";
import { InputInterface } from "../../../../models/FormSchema";

import {
  IExcelDataArray,
  IfilePath,
  IfilePathArray,
  IFormDataArray,
} from "./models/schemas";

import XLSX from "xlsx";
import moment from "moment";
import { processFiles } from "../../../../helpers/processFile";
import { FileBlobType, MySeverityType } from "../../../../models/base";
import { read, utils } from "xlsx";

interface IoperatorId {
  operatorId: string;
}

const MassiveMeters = ({ operatorId }: IoperatorId) => {
  const [fileInfo, setFileInfo] = useState<Blob>();
  const [filePath, setFilePath] = useState<any>([]);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccess2, setIsSuccess2] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
  const [textAlert, setTextAlert] = useState("");
  const [error, setError] = useState(false);
  const [excel, setExcel] = useState<IExcelDataArray | XLSX.WorkSheet>();
  const [formData, setFormData] = useState<IFormDataArray | any>([]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [msgInformation, setMsgInformation] = useState("");

  const readUploadFile = (files: Array<Blob> | any) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event?.target?.result;
      const wb = read(data, { type: "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const json = utils.sheet_to_json(ws);
      console.log(json);
      setExcel(json);
    };
    reader.readAsArrayBuffer(files[0]);
    setFileInfo(files[0]);
    setIsSuccess(true);
  };

  type validateDates = {
    status: boolean;
    serie?: string;
  };

  const validateDates = (): validateDates | any => {
    let hasError = false;
    let serieError = "";
    excel?.some((field: any) => {
      const excelDate = field["Fecha calibración"];
      const issueDate = field["Fecha de emisión certificado de calibración"];
      if (typeof excelDate !== "string" || typeof issueDate !== "string") {
        hasError = true;
        serieError = field["Serie"];
        return true;
      }
      return false;
    });
    if (hasError) {
      return {
        status: false,
        serie: serieError,
      };
    } else {
      return { status: true };
    }
  };

  const onDrop = useCallback((e: Blob[]) => readUploadFile(e), []);

  const onDropPdf = useCallback(
    async (files: InputInterface) => {
      console.log(files.length);

      try {
        setIsLoadingFile(true);

        let filesExist = true;
        const requiredFiles = [] as string[];

        const { status } = validateDates();
        if (!status) {
          setTextAlert(
            `Las fechas en el serial ${
              validateDates().serie
            } no tienen el formato esperado ("YYYY-MM-DD")`
          );
          setSeverityAlert("error");
          throw new Error();
        } else {
          excel?.map((field: any) => {
            requiredFiles.push(
              field["Certificado calibración importación activa"],

              field["Certificado calibración exportación activa"],

              field["Certificado calibración importación reactiva"],

              field["Certificado calibración exportación reactiva"]
            );
          });

          const newArray = requiredFiles.filter(
            (file: String) => file !== undefined
          );

          const emptyFiles = [] as any;
          newArray.forEach((file: string) => {
            if (
              !files.find((f: any) => {
                return f.name.split(".")[0] === file;
              })
            ) {
              filesExist = false;
              emptyFiles.push(file);
            }
          });
          if (!filesExist) {
            newArray.forEach((file: string) => {
              if (files.find((f: any) => f.name.split(".")[0] === file)) {
                filesExist = false;
              }
            });
            setTextAlert(`Faltan los siguientes archivos : ${emptyFiles}`);
            console.log(emptyFiles.length);
            setSeverityAlert("error");
            // alert();
            setIsLoadingFile(false);
            return;
          }

          setFilePath(
            await processFiles(files, 100, setMsgInformation, uploadFile)
          );

          setIsLoadingFile(false);
        }
      } catch (error) {
        setIsLoadingFile(false);
        return error;
      } finally {
        setIsLoadingFile(false);
      }
    },
    [excel]
  );

  const mapObject = (): void | unknown => {
    const newArray: IFormDataArray = [];
    try {
      excel?.map((field: XLSX.WorkSheet) => {
        const pathActivityImportCertification =
          field["Certificado calibración importación activa"];

        const pathActivityExportCertification =
          field["Certificado calibración exportación activa"];
        const pathReactiveImportCertification =
          field["Certificado calibración importación reactiva"];
        const pathReactiveExportCertification =
          field["Certificado calibración exportación reactiva"];

        const object = {
          issue_date: moment(
            field["Fecha de emisión certificado de calibración"],
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD"),

          calibration_date: moment(
            field["Fecha calibración"],
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD"),
          issuer_entity: field["Ente Emisor certificado de calibración"],
        };
        newArray.push(
          !pathActivityExportCertification || !pathReactiveExportCertification
            ? {
                form_data: {
                  meter_serial: field.Serie,
                  is_grouped: true,
                  active_export_import: {
                    ...object,
                    //@ts-ignore
                    certificate_number: pathActivityImportCertification,
                    certificate_path: filePath
                      ? filePath?.find(
                          (f: IfilePath) => f[pathActivityImportCertification]
                        )[pathActivityImportCertification]
                      : null,
                  },

                  reactive_export_import: {
                    ...object,
                    certificate_number: pathReactiveImportCertification,
                    certificate_path: filePath
                      ? filePath?.find(
                          (f: string) => f[pathReactiveImportCertification]
                        )[pathReactiveImportCertification]
                      : null,
                  },
                },
                //@ts-ignore
                created_by: operatorId,
              }
            : {
                form_data: {
                  meter_serial: field.Serie,
                  is_grouped: false,
                  active_import: {
                    ...object,
                    certificate_number: pathActivityImportCertification,
                    certificate_path: filePath
                      ? filePath?.find(
                          (f: string) => f[pathActivityImportCertification]
                        )[pathActivityImportCertification]
                      : null,
                  },
                  active_export: {
                    ...object,
                    certificate_number: pathActivityExportCertification,
                    certificate_path: filePath
                      ? filePath.find(
                          (f: string) => f[pathActivityExportCertification]
                        )[pathActivityExportCertification]
                      : null,
                  },
                  reactive_import: {
                    ...object,
                    certificate_number: pathReactiveImportCertification,
                    certificate_path: filePath
                      ? filePath.find(
                          (f: string) => f[pathReactiveImportCertification]
                        )[pathReactiveImportCertification]
                      : null,
                  },
                  reactive_export: {
                    ...object,
                    certificate_number: pathReactiveExportCertification,
                    certificate_path: filePath
                      ? filePath.find(
                          (f: string) => f[pathReactiveExportCertification]
                        )[pathReactiveExportCertification]
                      : null,
                  },
                },
                created_by: operatorId,
              }
        );
        setFormData(newArray);
      });
    } catch (error: unknown) {
      // setError(true);
      setSeverityAlert("error");
      console.log(error);
      setTextAlert(`Ha ocurrido un error inesperado`);

      return error;
    }
  };

  const onClearFile = (): void => {
    setIsSuccess(false);
    setExcel([]);
    setFormData([]);
    setFilePath("");
    setProgress(0);
  };

  useEffect(() => {
    mapObject();
  }, [filePath]);

  const closeAlert = () => {
    setTextAlert("");
    setSeverityAlert("");
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoadingForm(true);
    try {
      await sendForm(
        "/inventory-documentation/meter-calibrations-certificate-massive",
        formData
      );
      setSeverityAlert("success");
      setExcel([]);
      setFormData([]);
      setTextAlert("Certificados creados correctamente");
    } catch (error) {
      setSeverityAlert("error");
      setTextAlert("Ha ocurrido un error al generar los certificados");
      setFilePath([]);
    } finally {
      setIsLoadingForm(false);
    }
  };

  const uploadFile = async (
    file: Blob | string
  ): Promise<string | undefined> => {
    setError(false);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess2);
      return res;
    } catch (err) {
      setSeverityAlert("error");
      setTextAlert("No se pudo generar los medidores");
      setIsSuccess2(false);
      setError(true);
    }
  };

  return (
    <>
      <Typography
        color="primary"
        sx={{
          fontWeight: "500",
          my: "10px",
        }}
        variant="h5"
      >
        Creación masiva de calibración de medidores
      </Typography>

      <Typography
        color="primary"
        sx={{
          fontWeight: "500",
          my: 3,
        }}
        variant="h6"
      >
        Adjunta archivo excel
      </Typography>

      {excel?.length > 0 ? (
        <CardPdf
          typeFile="xlsx"
          fileInfo={fileInfo as FileBlobType}
          progress={progress}
          isSuccess={isSuccess}
          onClearFile={onClearFile}
        />
      ) : (
        <>
          <DragZone onDrop={onDrop as FileBlobType} typeFile="xlsx" />
        </>
      )}

      <Typography
        color="primary"
        sx={{
          fontWeight: "500",
          my: 3,
        }}
        variant="h6"
      >
        Adjunta los archivos pdfs
      </Typography>

      {isLoadingFile || formData.length > 0 ? (
        <CardPdf
          // fileInfo={fileInfo}
          progress={progress}
          isSuccess={isSuccess2}
          onClearFile={onClearFile}
        />
      ) : (
        <DragZone onDrop={onDropPdf as FileBlobType} />
      )}

      {isLoadingFile && (
        <Box sx={{ textAlign: "center", mt: 5 }}>
          <Typography
            color="secondary"
            sx={{
              fontWeight: "500",
              my: 3,
            }}
            variant="h6"
          >
            Cargando...!
          </Typography>
          <img src={bombi} alt="waiting" width="150px" />
        </Box>
      )}

      {msgInformation}

      <LoadingButton
        variant="contained"
        fullWidth
        disabled={isLoadingFile || formData.length === 0}
        sx={{ my: 4 }}
        loading={isLoadingForm}
        onClick={handleSubmit}
      >
        Enviar
      </LoadingButton>

      {severityAlert && !isLoadingFile && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </>
  );
};
export default MassiveMeters;
