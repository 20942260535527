import { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography, styled } from "@enerbit/base";
import { libraryOptions } from "../../../const/libraryJson";
import OptionsMenu from "./OptionsMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/libraryStore";

interface Props {
  option: string;
}

const ConsultResults = ({ option }: Props) => {
  const data = useSelector((state: RootState) => state.library.data);
  const fileName = useSelector((state: RootState) => state.library.fileName);

  const contentRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (contentRef.current?.scrollHeight && contentRef.current?.clientHeight) {
      if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, []);

  const fileNames: Record<string, string> = {
    active_import: "Certificado Activa - Importada",
    active_export: "Certificado Activa - Exportada",
    reactive_import: "Certificado Reactiva - Importada",
    reactive_export: "Certificado Reactiva -  Exportada",
    active_export_import: "Certificado Activa Exportada - Importada",
    reactive_export_import: "Certificado Reactiva Exportada - Importada",
  };

  return (
    <>
      {option && (
        <Typography
          color={"primary"}
          sx={{ fontSize: "16px", fontWeight: 700, marginBottom: "12px" }}>
          {fileName}
        </Typography>
      )}
      <ListContainer ref={contentRef} style={{ overflowY: isOverflowing ? "scroll" : "hidden" }}>
        {option && option !== "meterCalibrationCertificate" && (
          <>
            {data.map((item, index) => (
              <div key={index}>
                <Container>
                  {item.extra && (
                    <Typography variant='subtitle1' color='primary.main'>
                      {fileName}
                    </Typography>
                  )}
                  {item.extra && (
                    <Box mt={1} mb={2}>
                      <Grid container rowSpacing={5} columnSpacing={6}>
                        {item.extra.map((e: any) => (
                          <Grid item>
                            <Typography variant='body1' color='neutral.700'>
                              {`${e.name}:`}
                            </Typography>
                            <Typography variant='body1' color='neutral.700' fontWeight={700}>
                              {e.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  <ActionsContainer>
                    <FileContainer>
                      <PdfIcon>PDF</PdfIcon>
                      <Box>
                        <Typography fontWeight={700} variant='subtitle1'>
                          {`${fileName}.pdf`}
                        </Typography>
                        <Typography sx={{ fontWeight: "400", color: "#667085", fontSize: "14px" }}>
                          {`Fecha de creación: ${item.creationDate}`}
                        </Typography>
                      </Box>
                    </FileContainer>
                    <MenuContainer>
                      <OptionsMenu file={item.authUrl} option={option} date={item.creationDate} />
                    </MenuContainer>
                  </ActionsContainer>
                </Container>
              </div>
            ))}
          </>
        )}
        {option === "meterCalibrationCertificate" && (
          <>
            {data.map((item, index) => (
              <div key={index}>
                <Container>
                  <Box mb={1}>
                    <Typography fontWeight={700} variant='subtitle1'>
                      {`${libraryOptions[option].title}`}
                    </Typography>
                    {item.extra && (
                      <Box mt={1} mb={2}>
                        <Grid container rowSpacing={5} columnSpacing={6}>
                          {item.extra.map((e: any) => (
                            <Grid item>
                              <Typography variant='body1' color='neutral.700'>
                                {`${e.name}:`}
                              </Typography>
                              <Typography variant='body1' color='neutral.700' fontWeight={700}>
                                {e.value}
                              </Typography>
                            </Grid>
                          ))}
                          <Grid item>
                            <Typography variant='body1' color='neutral.700'>
                              {`Fecha de creación:`}
                            </Typography>
                            <Typography variant='body1' color='neutral.700' fontWeight={700}>
                              {item.creationDate}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                  {item.files.map((f: any, i: number) => (
                    <div style={{ marginTop: "12px" }} key={i}>
                      <ActionsContainer>
                        <FileContainer key={i}>
                          <FileIcon>PDF</FileIcon>
                          <Box>
                            <Typography fontWeight={700} variant='subtitle2'>
                              {`${fileNames[f.name]}.pdf`}
                            </Typography>
                          </Box>
                        </FileContainer>
                        <MenuContainer>
                          <OptionsMenu
                            file={f.url}
                            fileName={fileNames[f.name]}
                            option={option}
                            date={f.creationDate}
                          />
                        </MenuContainer>
                      </ActionsContainer>
                    </div>
                  ))}
                </Container>
              </div>
            ))}
          </>
        )}
      </ListContainer>
    </>
  );
};

export default ConsultResults;

const Container = styled("div")`
  width: 100%;
  background-color: #f2f4f7;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 10px;
`;

const PdfIcon = styled("div")`
  width: 45px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;

const FileIcon = styled("div")`
  width: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;

const FileContainer = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ActionsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const MenuContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListContainer = styled("div")`
  height: 270px;
  margin-bottom: 100px;
  padding-right: 10px;
`;
